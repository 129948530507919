<i18n lang="yaml">
ru:
  select_city: 'Выберите город'
en:
  select_city: 'Select city'
</i18n>


<template>
  <div class="filter-elem1" v-click-outside="toggleSwitchClose">
    <span>{{ $t('select_city') }}</span>
    <div class="dropdown field" @click="toggleSwitch">
      <input :value="selectedCity ? selectedCity[0] : emptyCityName" readonly/>
      <button>
        <i class="svg-icon arrow-bold" :class="{'rotate-180': active}"></i>
      </button>
    </div>
    <slide-up-down :active="active" :duration="400" :responsive="true"
                   class="filter-cities" @close-end="changeCity">
        <div ref="cities_list" class="cities-list">
          <div v-if="city">
            <a href="#" @click.prevent="selectCity(null)">
              <span class="city-elem">{{ emptyCityName }}</span>
            </a>
          </div>
          <div v-for="city in cities">
            <a href="#" @click.prevent="selectCity(city)">
              <span class="city-elem">{{ city[0] }}</span>
            </a>
          </div>
        </div>
    </slide-up-down>
  </div>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down';

export default {
  components: {
    SlideUpDown,
  },
  props: ['cities', 'city'],
  data() {
    return {
      active: false,
      emptyCityName: 'Все города',
      selectedCity: this.city,
    }
  },
  watch: {
    city(val) {
      this.selectedCity = val;
    },
  },
  methods: {
    toggleSwitch() {
      this.active = !this.active;
    },
    toggleSwitchClose() {
      if (this.active) {
        this.active = false;
      }
    },
    changeCity() {
      this.$emit('change', this.selectedCity);
      if (this.$refs.cities_list) {
        this.$refs.cities_list.offsetParent.scrollTop = 0;
      }
    },
    selectCity(city) {
      this.selectedCity = city;
      this.toggleSwitch();
    },
  },
}
</script>
