<i18n lang="yaml">
ru:
  homepage: 'Главная'
en:
  homepage: 'Home'
</i18n>

<template>
  <div class="breadcrumbs" v-if="breadcrumbs && breadcrumbs.length">
    <div class="container">
      <template v-for="(bc, idx) in breadcrumbs">
        <nuxt-link v-if="idx === 0" :to="localePath('/')">{{ $t('homepage') }}</nuxt-link>
        <i v-if="idx === 0" class="svg-icon arrow-paginator"></i>
        <span v-if="idx === breadcrumbs.length - 1">{{ bc.name }}</span>
        <nuxt-link v-else :to="localePath(bc.link)">{{ bc.name }}</nuxt-link>
        <i v-if="breadcrumbs.length && idx !== breadcrumbs.length - 1" class="svg-icon arrow-paginator"></i>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['breadcrumbs'],
}
</script>
