<template>
  <div v-if="page && totalPages > 1">
      <div key="prev" class="arrow-page" :class="{'disabled': page === 1}">
        <span @click.prevent="$emit('change-page', page - 1)">
          <i class="svg-icon arrow-paginator rotate-180"></i>
        </span>
      </div>
      <div v-for="(i, idx) in visiblePages" :class="{'active': i && i === page}" :key="`page-${i}-${idx}-${page}`">
        <span v-if="i && i === page">{{ i }}</span>
        <span v-else-if="i" @click.prevent="$emit('change-page', i)">{{ i }}</span>
        <span v-else @click.prevent="">...</span>
      </div>
      <div key="next" class="arrow-page" :class="{'disabled': page === totalPages}">
        <span @click.prevent="$emit('change-page', page + 1)">
          <i class="svg-icon arrow-paginator"></i>
        </span>
      </div>
      <div v-if="showAllPage && totalPages > 1" key="all" class="all-page">
        <span @click.prevent="$emit('change-page', null)">{{ $t('all_pages') }}</span>
      </div>
  </div>
  <div v-else></div>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    pageMeasure: {
      type: Number,
      default: 10,
    },
    pagesNearCurrent: {
      type: Number,
      default: 5,
    },
    showPreviousPage: {
      type: Boolean,
      default: true,
    },
    showNextPage: {
      type: Boolean,
      default: true,
    },
    showAllPage: {
      type: Boolean,
      default: true,
    },
    totalItems: Number,
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    visiblePages() {
      const pagesAroundCurrent = Math.floor(this.pagesNearCurrent / 2);
      const midPagePos1 = this.page - pagesAroundCurrent;
      const midPagePos2 = this.page + pagesAroundCurrent;
      const endPagePos = this.totalPages - this.pagesNearCurrent;
      let pagesList = [1, this.page, this.totalPages];
      // add pages multiple by page measure
      for (let i = this.pageMeasure; i <= this.totalPages; i += this.pageMeasure) {
        pagesList.push(i);
      }
      // add pages around current (if current page is in the middle of the list)
      for (let i = midPagePos1; 1 <= i && i <= midPagePos2 && i <= this.totalPages; i++) {
        pagesList.push(i);
      }
      // add pages around current (if current page is in the beginning of the list)
      for (let i = 1; i <= this.totalPages && this.page <= this.pagesNearCurrent && i <= this.pagesNearCurrent; i++) {
        pagesList.push(i);
      }
      // add pages around current (if current page is in the end of the list)
      for (let i = this.totalPages; 1 <= i && endPagePos < this.page && endPagePos < i; i--) {
        pagesList.push(i);
      }
      pagesList = [...new Set(pagesList)].sort((a, b) => a - b);
      let dotsPosition = [];
      for (let i = 0; i < pagesList.length - 1; i++) {
        if (pagesList[i] && pagesList[i + 1] && pagesList[i + 1] - pagesList[i] > 1 && pagesList[i + 1] - pagesList[i] < this.pageMeasure) {
          dotsPosition.push(i + 1);
        }
      }
      dotsPosition.reverse().forEach((x) => {
        pagesList.splice(x, 0, null);
      });
      return pagesList;
    },
  },
}
</script>

<i18n lang="yaml">
ru:
  prev_page: 'Предыдущая страница'
  next_page: 'Следующая страница'
  all_pages: 'Все'
en:
  prev_page: 'Previous page'
  next_page: 'Next page'
  all_pages: 'All'
</i18n>
