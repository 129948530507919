<template>
  <transition appear name="modal">
    <div v-if="visible" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <button class="modal-close-button" @click="$emit('close')">
            <i class="svg-icon cross-little"></i>
          </button>

          <div class="modal-content-container">

          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button">
                OK
              </button>
            </slot>
          </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ['visible'],
}
</script>
