<template>
  <a v-if="linkItem.link_external" :href="linkItem.link_external" :target="linkItem.target_blank && '_blank'"
     :class="visibleClass">{{linkItem.title }}</a>
  <nuxt-link v-else-if="linkItem.link_page && localePath(linkItem.link_page) !== $route.path" :to="localePath(linkItem.link_page)"
             :class="visibleClass">{{ linkItem.title }}</nuxt-link>
  <span v-else-if="linkItem.link_page && localePath(linkItem.link_page) === $route.path" :class="visibleClass">{{ linkItem.title }}</span>
  <a v-else-if="linkItem.link_document" :href="linkItem.link_document" :target="linkItem.target_blank && '_blank'" :class="visibleClass"
  >{{ linkItem.title }}</a>
</template>

<script>
export default {
  props: ['linkItem', 'visibleClass'],
}
</script>
