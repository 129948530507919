<i18n lang="yaml">
ru:
  header_1: 'Перед началом работы просим'
  header_2: 'ознакомиться с условиями'
  go_to_help: 'Перейти в раздел «Помощь»'
  do_not_show: 'Больше не показывать сообщение'
en:
  header_1: 'Before working with this site'
  header_2: 'please read our terms of use'
  go_to_help: 'Go to the "Help" page'
  do_not_show: 'Do not show this message anymore'
</i18n>

<template>
  <div>
    <modal :visible="cookieTerms" @close="closeCookieTerms">
      <template v-slot:header>
        <h3>{{ $t('header_1') }}<br>{{ $t('header_2') }}</h3>
      </template>
      <template v-slot:footer>
        <button class="modal-default-button" @click="$router.push({path: localePath('/profile/help/')})">
          {{ $t('go_to_help') }}
        </button>
        <div class="checkbox">
          <label :class="{'checked': checkboxCookie}">
          <input v-model="checkboxCookie" class="styled-checkbox" type="checkbox">
          {{ $t('do_not_show') }}</label>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import {DbConfig} from '@/models/db-config';
import Modal from '@/components/modal';

const cookieName = 'cookie-terms';
const cookieNameSession = 'cookie-terms-session';

export default {
  components: {
    Modal,
  },
  props: ['layoutLoading'],
  data() {
    return {
      cookieTerms: false,
      checkboxCookie: false,
      dbConfig: {},
    }
  },
  watch: {
    checkboxCookie(value) {
      if (value) {
        Cookies.set(cookieName, '1');
      } else {
        Cookies.remove(cookieName);
      }
    },
    layoutLoading(value) {
      if (!value) {
        this.setVisibility();
      }
    },
  },
  methods: {
    closeCookieTerms() {
      this.cookieTerms = false;
      Cookies.set(cookieNameSession, '1');
    },
    setVisibility() {
      if (!Cookies.get(cookieName) && !Cookies.get(cookieNameSession) && this.$auth.loggedIn
        && this.dbConfig.display_help_modal && ![this.localePath('/profile/help/'), this.localePath('/login/')].includes(this.$route.path)
      ) {
        this.cookieTerms = true;
      }
    },
  },
  created() {
    if (!this.layoutLoading) {
      this.$nextTick(() => this.setVisibility());
    }
  },
  async fetch() {
    this.dbConfig = await DbConfig.config({count_loading: true}).first();
  },
}
</script>
