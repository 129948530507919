<i18n lang="yaml">
ru:
  to_top: 'Вверх'
en:
  to_top: 'Go up'
</i18n>

<template>
  <a href="#" class="toTop" @click.prevent="toTop" :aria-label="$t('to_top')">
    <i class="svg-icon arrow"></i>
  </a>
</template>

<script>
export default {
  name: "scrollToTopButton",
  methods: {
    toTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
