<template>
  <div class="company-elem">
    <nuxt-link :to="localePath(`/where-to-buy/company/${company.id}/`)" class="company-name">{{ company.name
      }}</nuxt-link>
    <div class="company-content">
      <div v-if="company.vehicle_types?.length" class="company-info specs">
        <span class="specs-header">{{ $t('specialization')}}</span>
        <div>
          <i v-for="vt in vehicleTypes.filter(x => company.vehicle_types.includes(x.id))"
             class="svg-icon" :class="vt.icon_class"></i>
        </div>
      </div>
      <div class="company-info">
        <div>
          <i class="svg-icon location"></i>
          <span>{{ company.address }}</span>
        </div>
      </div>
      <div class="company-info">
        <div v-if="company.phone">
          <i class="svg-icon phone"></i>
          <a :href="'tel:' + company.phone">{{ company.phone }}</a>
        </div>
        <div v-if="company.site_b2b">
          <i class="svg-icon site"></i>
          <a :href="company.site_b2b" target="_blank">{{ company.site_b2b }}</a>
        </div>
        <div v-if="company.site_b2c">
          <i class="svg-icon site"></i>
          <a :href="company.site_b2c" target="_blank">{{ company.site_b2c }}</a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: ['company', 'vehicleTypes'],
}
</script>
