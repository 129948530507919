<i18n lang="yaml">
ru:
  page_title: 'Ошибка'
  page_not_found: 'Вы ищете страницу, которой не существует.'
  go_to: 'Вы можете перейти на'
  main_page: 'Главную страницу'
  server_error: 'Ошибка сервера №'
en:
  page_title: 'Error'
  page_not_found: "The page you are looking for doesn't exist."
  go_to: 'You can go to the'
  main_page: 'Home page'
  server_error: 'Server error '
</i18n>

<template>
  <div class="container" v-if="error.statusCode === 404">
    <h1 class="text_align-center">{{ $t('page_not_found') }}</h1>
    <div class="text_align-center">{{ $t('go_to') }} <nuxt-link :to="localePath('/')">{{ $t('main_page') }}</nuxt-link></div>
  </div>
  <div class="container" v-else>
    <h1 class="text_align-center">{{ $t('server_error') }}{{ error.statusCode }}</h1>
  </div>
</template>

<script>
export default {
  layout: 'default',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  head() {
    return {
      title: `${this.$t('page_title')} - ${this.error.statusCode}`,
    }
  },
}
</script>
