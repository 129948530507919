<i18n lang="yaml">
ru:
  agreement_text: 'Работая с этим сайтом, вы даете свое согласие на использование файлов cookie. Это необходимо для нормального функционирования сайта.'
  learn_more_button: 'Подробнее'
en:
  agreement_text: 'Our website uses cookies to ensure that we give you the best user experiences.'
  learn_more_button: 'Learn more'
</i18n>

<template>
  <transition name="fade">`
    <div class="cookie">
      <span class="cookie-message">
        {{ $t('agreement_text') }}
        <a href="https://www.google.com/policies/technologies/cookies/" target="_blank" :aria-label="$t('learn_more')">{{ $t('learn_more_button') }}</a>
      </span>
      <button class="cookie-compliance" @click="setCookieValue">OK</button>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
    setCookieValue() {
      this.$emit('set-cookie');
    }
  }
}
</script>
