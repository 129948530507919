<template>
  <a v-show="dbConfig.whatsapp" class="whatsapp-button" :href="'https://wa.me/' + dbConfig.whatsapp" aria-label="WhatsApp">
    <i class="svg-icon whatsapp"></i>
  </a>
</template>

<script>
import {DbConfig} from '@/models/db-config';

export default {
  data() {
    return {
      dbConfig: {},
    }
  },
  async fetch() {
    this.dbConfig = await DbConfig.config({count_loading: true}).first();
  },
}
</script>
