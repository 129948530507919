<template>
  <main @scroll="onScroll" :class="{'no-scroll': innerLoading , 'phone-mode': !this.$device.isDesktopOrTablet}">
    <transition>
      <div v-show="innerLoading" class="loading-overlay">
        <div class="spinner"></div>
      </div>
    </transition>
    <Header/>
    <Notification :key="`notifications-${componentsKey}`"/>
    <div class="content-global" :style="{'margin-right': `${scrollBoxWidth}px`}">
      <Nuxt/>
      <addresses-page v-if="isWhereToBuyPage"/>
    </div>
    <Footer :style="{'margin-right': `${scrollBoxWidth}px`}"/>
    <scroll-to-top-button v-if="isVisible"/>
    <cookie v-show="cookieShow" @set-cookie="setCookie"/>
    <cookie-terms :layout-loading="innerLoading"/>
    <whatsapp-button/>
    <check-build-version/>
  </main>
</template>

<script>
import Cookies from 'js-cookie';
import {mixinDetectingMobile} from "@/mixins";

import AddressesPage from '@/components/where-to-buy/addresses-page';
import CheckBuildVersion from '@/components/check-build-version';
import Cookie from '@/components/cookie';
import CookieTerms from '@/components/cookie-terms';
import Header from '@/components/header';
import Notification from '@/components/notification';
import ScrollToTopButton from '@/components/scrollToTopButton';
import WhatsappButton from '@/components/whatsapp-button'

export default {
  components: {
    AddressesPage,
    CheckBuildVersion,
    Cookie,
    CookieTerms,
    'Footer': () => import(`@/components/${process.env.VENDOR}/footer`),
    Header,
    Notification,
    ScrollToTopButton,
    WhatsappButton,
  },
  mixins: [mixinDetectingMobile],
  data() {
    return {
      isVisible: false,
      cookieShow: false,
      innerLoading: false,
      scrollBoxWidth: 0,
    }
  },
  watch: {
    loading: {
      handler(value) {
        this.$nextTick(() => this.innerLoading = value);
      },
      immediate: true,
    },
    hideScrollbar: {
      handler(value) {
        if (value) {
          try {
            const scrollBox = document.createElement('div');
            scrollBox.style.overflow = 'scroll';
            document.body.appendChild(scrollBox);
            const scrollBoxWidth = scrollBox.offsetWidth - scrollBox.clientWidth;
            scrollBox.remove();
            if (document.body.clientHeight > window.innerHeight) {
              this.scrollBoxWidth = scrollBoxWidth;
            }
          } catch (e) {}
        } else {
          this.scrollBoxWidth = 0;
        }
        try {
          if (value) {
            document.body.classList.add('no-scroll');
          } else {
            document.body.classList.remove('no-scroll');
          }
        } catch (e) {}
      },
      immediate: true,
    },
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    hideScrollbar() {
      return this.$store.getters.hideScrollbar;
    },
    isMainPage() {
      return this.$route.path === this.localePath('/');
    },
    componentsKey() {
      return `${this.$route.path}-${this.$auth.loggedIn}`;
    },
    isWhereToBuyPage() {
      return this.$route.name.startsWith('where-to-buy___') || this.$route.name.startsWith('where-to-buy-city___');
    },
  },
  created() {
    if (typeof window !== "undefined") {
      window.addEventListener('scroll', this.onScroll);
    }
  },
  mounted() {
    if (!Cookies.get('wetz')) {
      this.cookieShow = true;
    }
  },
  destroyed() {
    if (typeof window !== "undefined") {
      window.removeEventListener('scroll', this.onScroll);
    }
  },
  methods: {
    onScroll() {
      this.isVisible = window.scrollY > 50
    },
    setCookie() {
      Cookies.set('wetz', '1');
      this.cookieShow = false;
    },
  },
}
</script>
