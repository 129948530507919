<template>
  <div class="filter-elem2" v-click-outside="toggleSwitchClose">
    <span>{{ $t('specialization') }}</span>
    <div class="dropdown field" @click="toggleSwitch">
      <input :value="vehicleType ? vehicleType.name : $t('all_vehicles')" readonly/>
      <button>
        <i class="svg-icon arrow-bold" :class="{'rotate-180': active}"></i>
      </button>
    </div>
    <slide-up-down :active="active" :duration="400" :responsive="true" class="filter-specs"
                   @close-end="changeVehicleType">
        <div ref="vehicle_types_list" class="spec-list">
          <div v-if="vehicleType">
            <a href="#" @click.prevent="selectVehicleType(null)">
              <span class="spec-elem">{{ $t('all_vehicles') }}</span>
            </a>
          </div>
          <div v-for="vt in allVehicleTypes">
            <a href="#" @click.prevent="selectVehicleType(vt)">
              <span class="spec-elem">{{ vt.name }}</span>
            </a>
          </div>
        </div>
    </slide-up-down>
  </div>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down';

export default {
  props: ['vehicleType', 'allVehicleTypes'],
  components: {
    SlideUpDown,
  },
  data() {
    return {
      active: false,
      selectedVehicleType: this.vehicleType,
    }
  },
  watch: {
    vehicleType(val) {
      this.selectedVehicleType = val;
    },
  },
  methods: {
    toggleSwitch() {
      this.active = !this.active;
    },
    changeVehicleType() {
      this.$emit('change', this.selectedVehicleType);
      if (this.$refs.vehicle_types_list) {
        this.$refs.vehicle_types_list.scrollTop = 0;
      }
    },
    selectVehicleType(vt) {
      this.selectedVehicleType = vt;
      this.toggleSwitch();
    },
    toggleSwitchClose() {
      if (this.active) {
        this.active = false;
      }
    },
  },
}
</script>
