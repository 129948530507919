<template>
  <yandex-map :settings="yandexMapSettings" width="100%" height="500px" :zIndex="1" :cursorGrab="true" >
    <yandex-map-default-scheme-layer/>
    <yandex-map-default-features-layer/>
    <yandex-map-controls :settings="{position: 'right'}">
      <yandex-map-zoom-control/>
    </yandex-map-controls>
    <yandex-map-clusterer zoom-on-cluster-click>
      <yandex-map-marker v-for="(company, i) in companies" :key="i" :settings="getCompanyMarkerSettings(company)">
        <div class="map-marker" @click="redirectToCompanyPage(company)"></div>
      </yandex-map-marker>
      <template #cluster="{length}">
        <div class="map-cluster">{{ length }}</div>
      </template>
    </yandex-map-clusterer>
    <yandex-map-hint hint-property="hint">
      <template #default="{content}">
        <div class="map-popup">
          <div>{{ content?.name }}</div>
          <div>{{ content?.address }}</div>
        </div>
      </template>
    </yandex-map-hint>
  </yandex-map>
</template>


<script>
import {
  YandexMap,
  YandexMapClusterer,
  YandexMapControls,
  YandexMapDefaultFeaturesLayer,
  YandexMapDefaultSchemeLayer,
  YandexMapHint,
  YandexMapMarker,
  YandexMapZoomControl,
} from 'vue-yandex-maps';

const _moscowCoords = [37.64, 55.76];

export default {
  props: ['companies', 'city'],
  components: {
    YandexMap,
    YandexMapClusterer,
    YandexMapControls,
    YandexMapDefaultFeaturesLayer,
    YandexMapDefaultSchemeLayer,
    YandexMapHint,
    YandexMapMarker,
    YandexMapZoomControl,
  },
  computed: {
    yandexMapSettings() {
      return {
        location: {
          center: this.centerCoord,
          zoom: this.zoom,
        },
      };
    },
    markers() {
      return this.companies.map((x) => ({coordinates: [x.longitude, x.latitude]}));
    },
    onePlaceMode() {
      return this.companies.length === 1 || [...new Set(this.companies.map(x => [x.latitude, x.longitude].join('-')))].length === 1;
    },
    centerCoordLatitude() {
      let values = this.companies.map(x => x.latitude);
      let min = Math.min(...values);
      let max = Math.max(...values);
      return (min + max) / 2;
    },
    centerCoordLongitude() {
      let values = this.companies.map(x => x.longitude);
      let min = Math.min(...values);
      let max = Math.max(...values);
      if (max - min > 180) {
        values = values.map(val => val < max - 180 ? val + 360 : val);
        min = Math.min(...values);
        max = Math.max(...values);
      }
      let result = (min + max) / 2;
      if (result > 180) {
        result -= 360;
      }
      return result;
    },
    centerCoord() {
      if (this.onePlaceMode) {
        return [this.companies[0].longitude, this.companies[0].latitude];
      }
      if (this.city) {
        return [this.centerCoordLongitude, this.centerCoordLatitude];
      }
      return _moscowCoords;
    },
    zoom() {
      if (this.onePlaceMode) {
        return 12;
      }
      if (this.city) {
        return 8;
      }
      return 4;
    },
  },
  methods: {
    getCompanyMarkerSettings(company) {
      return {
        coordinates: [company.longitude, company.latitude],
        properties: {
          hint: company,
        },
      }
    },
    redirectToCompanyPage(company) {
      this.$router.push({
        path: this.localePath(`/where-to-buy/company/${company.id}/`),
      });
    },
  },
}
</script>
