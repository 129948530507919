<template></template>

<script>
const _secondsBetweenChecks = 60;

export default {
  mounted() {
    this.$store.dispatch('setBuildVersion', () => {
      setInterval(() => {
        this.$axios.$get('/last-build-version/', {progress: false})
          .then((response) => {
            if (response !== this.$store.getters.buildVersion) {
              window.location.reload();
            }
          }).catch(() => {});
      }, _secondsBetweenChecks * 1000);
    });
  },
}
</script>
